import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';
import SocialProfile from '../../components/social-profile/social-profile';
import {
  IoLogoFacebook,
  IoLogoTwitter,
  IoLogoInstagram,
  IoLogoLinkedin,
} from 'react-icons/io';
import {
  AboutWrapper,
  AboutImage,
  AboutPageTitle,
  AboutDetails,
  SocialProfiles,
} from './style';
import Portfolio from './portfolio';

const SocialLinks = [/*
  {
    icon: <IoLogoFacebook />,
    url: 'https://www.facebook.com/redqinc/',
    tooltip: 'Facebook',
  },
  {
    icon: <IoLogoInstagram />,
    url: 'https://www.instagram.com/redqinc/',
    tooltip: 'Instagram',
  },
  {
    icon: <IoLogoTwitter />,
    url: 'https://twitter.com/redqinc',
    tooltip: 'Twitter',
  },*/
  {
    icon: <IoLogoLinkedin />,
    url: 'https://www.linkedin.com/in/thibault-vandermosten-005541116/',
    tooltip: 'Linked In',
  },
];

interface AboutProps {}

const About: React.FunctionComponent<AboutProps> = () => {
  const Data = useStaticQuery(graphql`
    query {
      avatar: file(absolutePath: { regex: "/about.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 1770, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      site {
        siteMetadata {
          author
          about
        }
      }
    }
  `);

  return (
    <AboutWrapper>
      <AboutPageTitle>
        <h2>About Thibault Vandermosten</h2>
        </AboutPageTitle>

      {/*<AboutImage>
        <Image fluid={Data.avatar.childImageSharp.fluid} alt="author" />
      </AboutImage>*/}

      <AboutDetails>
        <h3>Hey there, how are you doing :) ?</h3>
        <p>
        
        I am an entrepreneur dedicated to developing fair, scalable, and value-driven businesses, with a strong focus on digital automated products. I believe in creating solutions that offer unique value, and I have successfully brought these visions to life—either on my own or through collaborative leadership (check out some examples below). As an advisor, I enjoy providing clarity through a blend of logic, empathy, and purpose-driven thinking.
        <br/><br/>
        I'm particularly passionate about diverse fields, ranging from existential philosophy and psychology to ethics in AI and the evolution of gaming.
        <br/><br/>
        My background includes a Masters degree in Artificial Intelligence, and I have embraced unique experiences around the world, from learning Spanish as a janitor in Santiago to falling in love with New Zealand while as a mountain lodge manager. My career journey has taken me from Solutions Architect to Entrepreneurship, Product Management, and Advisory roles.
        <br/><br/>
        This blog is a space for me to share the insights I have gained over the years, and I genuinely hope you find them valuable. Wishing you a wonderful day ahead!
        </p>
        <p>
          
          <Portfolio/>
        </p>

        <SocialProfiles>
          <SocialProfile items={SocialLinks} />
        </SocialProfiles>
      </AboutDetails>
    </AboutWrapper>
  );
};

export default About;
