import React from 'react';
import { Link } from 'gatsby';
import './portfolio.css'; // We'll add styles here
import LogoImage from '../../images/logo2.png';
import IPSLogo from '../../images/IPSLogo.png';
import PersonasLogo from '../../images/logosquare240.png';
import LogoPlay from '../../images/logoplay.png';
import LogoGH from '../../images/GoingHomeLogo.jpg';
import GGlogo from '../../images/GiftGenieLogo.png';
import PRlogo from '../../images/PRlogo.png';
import ZapLogo from '../../images/ZapLogo.png';
import blogLogo from '../../images/newsletter-bg.webp';

const projects = [
  {
    title: 'Irish Property Stats',
    link: 'https://irishpropertystats.com',
    description:
      'Some helpful stats about Irish Residential Real estate, a better UX for the property register. <br/>  Tech: NextJS, Express - Stage: MVP - Year: 2024',
    image: IPSLogo,
  },
  {
    title: 'Play now or later?',
    link: 'https://playnoworlater.com',
    description:
      ' A community voting solution to early access games <br/> Tech: ReactJS, Firebase - Stage: MVP - Year: 2024',
    image: LogoPlay,
  },
  {
    title: 'Going Home',
    link: 'https://korell.itch.io/going-home',
    description:
      'Arcade game for people tired of the fake mobile ads <br/> Tech: Unreal Engine 5, Python, GIMP  - Year: 2024',
    image: LogoGH,
  },
  {
    title: 'Gift Genie',
    link: 'https://giftgenie.org',
    description:
      'Easily find the right gift for anyone <br/> Tech Stack: React JS, Firebase, OpenAI API - Stage: MVP - Year: 2023',
    image: GGlogo,
  },
  {
    title: 'Personas',
    link: 'https://personas360.com',
    description:
      'Discover who you are, who others think you are, and improve the rest of your life. The only 360 online big 5 test.<br/>Tech Stack: React JS, Firebase, Node.js, Git, Paddle API, Matomo - Operating business - Year: 2020 ',
    image: PersonasLogo,
  },
  {
    title: 'My Blog ',
    link: '#', // Assuming this points to your blog homepage
    description:
      'This is a kind of open journal, where I try to record some of my learnings. Mostly for myself to remember or retrospect in the future. <br/> Tech: React JS, Gatsby - Year: 2020 ',
    image: blogLogo,
  },
  {
    title: 'Zap (Discontinued) ',
    link: '#',
    description:
      ' A Tinder for temp jobs in New-Zealand. Filling a hole in the market in between Temp agencies and CVs. Had to be terminated due to timing (early COVID). <br/> Tech: Java, Android SDK, Google Play - Year: 2020',
    image: ZapLogo,
  },
  {
    title: 'Poetic Riddles (Discontinued)',
    link: '#',
    description:
      'An android game containing 50 poetic riddles originally written <br/> Tech: Java, Android SDK, Google Play  - Year: 2017',
    image: PRlogo,
  },
];

const Portfolio = () => {
  return (
    <div>
      <h3>Products</h3>
      <div className="projects-container">
        {projects.map((project, index) => (
          <div key={index} className="project-item">
            <div className="image-container">
              <img src={project.image} alt={project.title} />
            </div>
            <div className="text-container">
              <h4>
                {project.link ? (
                  <a
                    href={project.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {project.title}
                  </a>
                ) : (
                  project.title
                )}
              </h4>
              <p dangerouslySetInnerHTML={{ __html: project.description }}></p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Portfolio;
